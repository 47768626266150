import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import useMapBounds from '../../../hooks/useMapBounds';
import useSelectedStation from '../../../hooks/useSelectedStation';
import useChargingStationsNearRoute from '../../../hooks/useChargingStationsNearRoute';

import setupGoogleMapsAutocomplete from '../../../utils/setupGoogleMapsAutocomplete';
import isSuperChargingStation from '../../../utils/predicates/isSuperChargingStation';

import BaseGoogleMapReact from '../BaseGoogleMapReact/BaseGoogleMapReact';
import MapControlPanel from '../MapControlPanel/MapControlPanel';
import ChargingStationsList from '../ChargingStationsList/ChargingStationsList';
import Checkbox from '../../shared/InputElements/Checkbox';
import useGeoJsonUrls from '../../../hooks/useGeojsonUrls';

import ChargingPinPublic from './../../../client_customizations/assets/images/icons/charger-pin-public.png';
import ChargingPinHighPower from './../../../client_customizations/assets/images/icons/charger-pin-high-power.png';
import ChargingPinDefault from './../../../client_customizations/assets/images/icons/charger-pin-private.png';

import './TravelRouteMap.scss';
import { FormattedMessage } from 'react-intl';

const overviewPathAsLinestring = (overviewPath) => {
  const coordsAsText = overviewPath
    .map((coords) => `${coords.lng()} ${coords.lat()}`)
    .join(', ');
  return `LINESTRING(${coordsAsText})`;
};

const TravelRouteMap = ({
  chargingStationsFilterFn,
  isVisible = true,
  pspsEvents = [],
}) => {
  const [distance, setDistance] = useState(5);
  const [startAddress, setStartAddress] = useState(
    process.env.REACT_APP_PAGES_MAP_ROUTE_START_ADDRESS ||
      'Los Angeles, CA, USA'
  );
  const [destinationAddress, setDestinationAddress] = useState(
    process.env.REACT_APP_PAGES_MAP_ROUTE_END_ADDRESS || 'Las Vegas, NV, USA'
  );
  const [isFetchingRoute, setIsFetchingRoute] = useState(false);
  const [routeError, setRouteError] = useState();
  const [isIgnoringLowPowerStations, setIsIgnoringLowPowerStations] =
    useState(false);
  const [routeDistanceInMeters, setRouteDistanceInMeters] = useState();

  const directionsServiceRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const autocompleteStartLocationRef = useRef();
  const autocompleteEndLocationRef = useRef();

  useEffect(() => {
    const stationAddress = localStorage.getItem('plan-route-station');

    if (stationAddress) {
      setDestinationAddress(stationAddress);
      localStorage.removeItem('plan-route-station');
    }
  }, []);

  const { registerMapBoundsListeners, filterWithinBounds } = useMapBounds();

  const {
    chargingStations,
    fetchChargingStations,
    error: chargingStationsError,
  } = useChargingStationsNearRoute();

  const [selectedStation, selectStation] = useSelectedStation(chargingStations);

  const registerGeojsonUrlMap = useGeoJsonUrls(
    pspsEvents.flatMap((event) => event.file_urls)
  );

  const onCompleteStartLocation = () => {
    const address =
      autocompleteStartLocationRef.current.getPlace().formatted_address;
    setStartAddress(address);
  };

  const onCompleteEndLocation = () => {
    const address =
      autocompleteEndLocationRef.current.getPlace().formatted_address;
    setDestinationAddress(address);
  };

  const saveMapRefs = ({ map }) => {
    /* eslint-disable no-undef */
    directionsServiceRef.current = new google.maps.DirectionsService();
    directionsRendererRef.current = new google.maps.DirectionsRenderer();
    /* eslint-enable no-undef */

    setupGoogleMapsAutocomplete(
      autocompleteStartLocationRef,
      'travel-route-map-start-location',
      onCompleteStartLocation
    );
    setupGoogleMapsAutocomplete(
      autocompleteEndLocationRef,
      'travel-route-map-end-location',
      onCompleteEndLocation
    );

    directionsRendererRef.current.setMap(map);
    registerMapBoundsListeners(map);
    fetchRoute({ isTrackingEvent: false });

    registerGeojsonUrlMap(map);
  };

  const isSubmittingAllowed = () =>
    !isFetchingRoute && startAddress && destinationAddress;

  const fetchRoute = () => {
    if (!startAddress || !destinationAddress) return;
    setIsFetchingRoute(true);
    setRouteError(undefined);
    setRouteDistanceInMeters(undefined);

    directionsServiceRef.current.route(
      {
        origin: startAddress,
        destination: destinationAddress,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRendererRef.current.setDirections(response);

          if (response.routes && response.routes.length > 0) {
            // See https://developer.nrel.gov/docs/transportation/alt-fuel-stations-v1/nearby-route/
            const linestring = overviewPathAsLinestring(
              response.routes[0].overview_path
            );

            fetchChargingStations({ route: linestring, distance });
            const distanceInMeters = response.routes[0].legs.reduce(
              (totalDistance, leg) => totalDistance + leg.distance.value,
              0
            );
            setRouteDistanceInMeters(distanceInMeters);
          }
        } else {
          setRouteError('Error fetching route');
        }
        setIsFetchingRoute(false);
      }
    );
  };

  useEffect(() => {
    if (!directionsServiceRef.current) return;

    fetchRoute({ isTrackingEvent: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distance]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isSubmittingAllowed()) {
      fetchRoute({ isTrackingEvent: false });
    }
  };

  const visibleChargingStations = filterWithinBounds(chargingStations).filter(
    (station) =>
      isIgnoringLowPowerStations ? isSuperChargingStation(station) : true
  );
  const filteredChargingStations = chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  return (
    <div className="TravelRouteMap">
      <div className="col-sm-12">
        <MapControlPanel
        // selectedStation={selectedStation}
        // chargingStations={filteredChargingStations}
        >
          <div className="form-group">
            <label htmlFor="travel-route-map-start-location">
              Start Location
            </label>
            <input
              id="travel-route-map-start-location"
              type="text"
              className="form-control"
              onChange={(e) => setStartAddress(e.target.value)}
              value={startAddress}
              placeholder="Start Location"
              disabled={isFetchingRoute}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-group">
            <label htmlFor="travel-route-map-end-location">End Location</label>
            <input
              id="travel-route-map-end-location"
              type="text"
              className="form-control"
              onChange={(e) => setDestinationAddress(e.target.value)}
              value={destinationAddress}
              placeholder="End Location"
              disabled={isFetchingRoute}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-group col-lg-2.5">
            <label
              className="label-subTitle"
              htmlFor="travel-route-map-select-radius"
            >
              Show Charging Stations
            </label>
            <select
              id="travel-route-map-select-radius"
              className="form-control"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
            >
              <option value={1}>Within 1 mile</option>
              <option value={2}>Within 2 miles</option>
              <option value={5}>Within 5 miles</option>
            </select>
          </div>

          <div className="form-group checkbox col-lg-2.5">
            <Checkbox
              id="show-only-supercharging-stations-route"
              value={isIgnoringLowPowerStations}
              label="High Power Stations Only"
              handler={(e) =>
                setIsIgnoringLowPowerStations((currentValue) => !currentValue)
              }
            />
          </div>
          <div className="form-group last-item col-lg-2">
            <button
              className="btn btn-aps btn-detail"
              type="button"
              onClick={fetchRoute}
              disabled={!isSubmittingAllowed()}
            >
              Map Route
            </button>
          </div>
          {chargingStationsError && (
            <p className="text-danger">
              There was an error fetching charging stations. Please try again.
            </p>
          )}
          {routeError && (
            <p className="text-danger">
              We could not find that route. Please check the addresses and try
              again.
            </p>
          )}
        </MapControlPanel>
      </div>

      <div className="col-sm-12">
        <div className="map-container">
          {routeDistanceInMeters && (
            <div className="route-distance-legend">
              This route is {Math.ceil(routeDistanceInMeters / 1609)} miles
            </div>
          )}
          {isVisible && (
            <BaseGoogleMapReact
              onGoogleApiLoaded={saveMapRefs}
              onHoverOnStation={selectStation}
              onClickMarker={() => {}}
              chargingStations={filteredChargingStations}
              selectedStation={selectedStation}
              onChange={() => {}}
            />
          )}
        </div>
      </div>

      <div className="col-sm-12">
        <div className="LegendContainer">
          <div className="charger-type legendItem">
            <img src={ChargingPinHighPower} alt="" />
            <div className="textContainer">
              <b>
                <FormattedMessage
                  id="chargingMap.highPowerStations"
                  defaultMessage="High Power Stations"
                  description="High Power Stations Text"
                />
              </b>
              <span>
                <FormattedMessage
                  id="chargingMap.DCChargers"
                  defaultMessage="DC fast charge"
                  description="DC fast charge or superchargers Text"
                />
              </span>
            </div>
          </div>
          <div className="charger-type legendItem">
            <img src={ChargingPinPublic} alt="" />
            <div className="textContainer">
              <b>
                <FormattedMessage
                  id="chargingMap.public"
                  defaultMessage="Public Stations"
                  description="Public Stations"
                />
              </b>
              <span>
                <FormattedMessage
                  id="chargingMap.businessGovernment"
                  defaultMessage="Level 2 chargers"
                  description="Level 2 chargers"
                />
              </span>
            </div>
          </div>
          <div className="charger-type legendItem">
            <img src={ChargingPinDefault} alt="" />
            <div className="textContainer">
              <b>
                <FormattedMessage
                  id="chargingMap.otherStations"
                  defaultMessage="Other Stations"
                  description="Other Types of Stations"
                />
              </b>
              <span>
                <FormattedMessage
                  id="chargingMap.privateStations"
                  defaultMessage="Private Stations"
                  description="Private Stations"
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <ChargingStationsList chargingStations={filteredChargingStations} />
      </div>
    </div>
  );
};

TravelRouteMap.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default TravelRouteMap;
