import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl } from 'react-intl';

const EvSortControls = ({inventory, mobile}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const updateSort = value => {
    let newPrefs = {
      vehicleSortType: value.split("-")[0],
      vehicleSortDirection: value.split("-")[1]
    };
    userPrefs.set(newPrefs);
  };

  let sortOptionValue = [
    userPrefs.get("vehicleSortType"),
    userPrefs.get("vehicleSortDirection")
  ].join("-");

  return (
    <div className="EVsSort">
      <form className="form-inline">
        <label
          htmlFor="vehicle-sort-dropdown"
          className="mr-2"
          style={mobile ? { fontWeight: '400' } : {}}
        >
          <FormattedMessage
            id="evSort.title"
            defaultMessage="Sort By"
            description="EV Sort By Title"
          />
        </label>
        <select
          style={{ width: '140px' }}
          id="vehicle-sort-dropdown"
          className="form-control"
          value={sortOptionValue}
          onChange={(e) => updateSort(e.target.value)}
        >
          <option value="electric_range-desc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: 'evSort.electricRange',
                  defaultMessage: 'Electric Range',
                })
              : 'Electric Range'}
          </option>
          <option value="price-asc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: 'evSort.priceLtH',
                  defaultMessage: 'Price: Low to High',
                })
              : 'Price: Low to High'}
          </option>
          <option value="price-desc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: 'evSort.priceHtL',
                  defaultMessage: 'Price: High to Low',
                })
              : 'Price: High to Low'}
          </option>
          <option value="alphabetical-asc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: 'evSort.alphabeticaldesc',
                  defaultMessage: 'Alphabetical',
                })
              : 'Alphabetical'}
          </option>
          <option value="alphabetical-desc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: 'evSort.alphabeticalasc',
                  defaultMessage: 'Alphabetical',
                })
              : 'Alphabetical'}
          </option>
          <option value="charge-asc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: 'evSort.chargeasc',
                  defaultMessage: 'Fastest Charge',
                })
              : 'Fastest Charge'}
          </option>
        </select>
      </form>
    </div>
  );
};

export default EvSortControls;
