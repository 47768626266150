import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IncentiveCard from '../IncentiveCard/IncentiveCard';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {
  sortIncentivesCatalog,
  providerFilter,
} from '../../functions/incentives/Sort/sortIncentivesCatalog';
import UserPrefsContext from '../../context/UserPrefs/UserPrefsContext';

import greyArrow from '../../client_customizations/assets/images/icons/arrow_carousel_grey.svg';
import blackArrow from '../../client_customizations/assets/images/icons/arrow_carousel_black.svg';
const IncentiveCatalog = ({ incentives, carousel, main }) => {
  const [firstIncentive, setFirstIncentive] = useState(0
  );


  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);
  useEffect(() => {
         const handleResize = () => {
                setWindowWidth(document.documentElement.clientWidth);
         };
         window.addEventListener('resize', handleResize);
         return () => {
                window.removeEventListener('resize', handleResize);
         };
}, []);
  const incentiveCount = incentives ? incentives.length : 0;

  const maxIndex = incentiveCount;

  const userPrefs = useContext(UserPrefsContext);
  

  const sortedIncentives = sortIncentivesCatalog(
    incentives,
    userPrefs.get('incentiveSortType'),
    userPrefs.get('incentiveSortDirection')
  );


   const incentiveIndices = [
     firstIncentive,
     firstIncentive + 1 <= maxIndex ? firstIncentive + 1 : firstIncentive + 1 - incentiveCount,
     firstIncentive + 2 <= maxIndex ? firstIncentive + 2 : firstIncentive + 2 - incentiveCount,
     firstIncentive + 3 <= maxIndex ? firstIncentive + 3 : firstIncentive + 3 - incentiveCount,
     firstIncentive + 4 <= maxIndex ? firstIncentive + 4 : firstIncentive + 4 - incentiveCount,
   ];
   
     const providerFilteredIncentives = providerFilter(
       sortedIncentives,
       userPrefs.get('electricityProvider')
     );

     const filteredIncentives =  []
     if (providerFilteredIncentives[incentiveIndices[0]]) {
        filteredIncentives.push(
          providerFilteredIncentives[incentiveIndices[0]]
        );
     }
      
      

    const mobile = windowWidth <= 980;
    const tablet = windowWidth > 980  && windowWidth < 1150;
    let y;

       if(!mobile && !tablet) {
            y = 4
       } else if(!mobile && tablet) {
            y = 3
       } else {
           y = 0
       }

       for (let i = 0; i < y; i++) {
         if (providerFilteredIncentives[incentiveIndices[i + 1]]) {
            filteredIncentives.push(
               providerFilteredIncentives[incentiveIndices[i + 1]]
         )}
       }

    if (!incentives) return <LoadingSpinner />;

  return carousel ? (
    <div className="carousel-incentives-list">
      <div className="col-sm-1 text-right">
        <button
          className="btn"
          style={{ fontSize: '21px' }}
          disabled={firstIncentive === 0}
          onClick={() =>
            firstIncentive === 0
              ? setFirstIncentive(maxIndex)
              : setFirstIncentive(firstIncentive - 1)
          }
        >
          {firstIncentive === 0 ? (
            <img src={greyArrow} alt="Carousel Left Arrow" />
          ) : (
            <img src={blackArrow} className="flip" alt="Carousel Left Arrow" />
          )}
        </button>
      </div>
      {filteredIncentives?.map((incentive, index) => (
            <IncentiveCard incentive={incentive} key={index} />
          ))}
      <div className="col-sm-1">
        <button
          className="btn"
          style={{ fontSize: '21px' }}
          disabled={
            firstIncentive + filteredIncentives.length >=
            providerFilteredIncentives.length
          }
          onClick={() =>
            firstIncentive === maxIndex
              ? setFirstIncentive(0)
              : setFirstIncentive(firstIncentive + 1)
          }
        >
          {firstIncentive + filteredIncentives.length >=
          providerFilteredIncentives.length ? (
            <img src={greyArrow} className="flip" alt="Carousel Right Arrow" />
          ) : (
            <img src={blackArrow} alt="Carousel Right Arrow" />
          )}
        </button>
      </div>
    </div>
  ) : (
    <div className="incentives-list">
      {providerFilteredIncentives?.map((incentive, index) => (
        <IncentiveCard incentive={incentive} key={index} />
      ))}
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool,
};
