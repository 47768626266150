import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EVCard from '../../../components/EVCard/EVCard';
import { FormattedMessage } from 'react-intl';
import './VehicleCarousel.scss';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import greyArrow from "../../assets/images/icons/arrow_carousel_grey.svg";
import blackArrow from "../../assets/images/icons/arrow_carousel_black.svg";

const VehicleCarousel = ({ electricVehicles, userLocation }) => {
  const [firstEv, setFirstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);
       useEffect(() => {
              const handleResize = () => {
                     setWindowWidth(document.documentElement.clientWidth);
              };
              window.addEventListener('resize', handleResize);
              return () => {
                     window.removeEventListener('resize', handleResize);
              };
     }, []);

  if (!electricVehicles)
    return (
      <section className="container-fluid grey">
        <LoadingSpinner />
      </section>
    );

  if (!electricVehicles || electricVehicles.length <= 3) return null;
  if (!userLocation) return null;

  const vehicleCount = electricVehicles ? electricVehicles.length : 'many';

  const MNGoldDealers = new Set([
    'Mitsubishi',
    'Nissan',
    'Chevrolet',
    'Ford',
    'Volkswagen',
    'Toyota',
    'Tesla',
    'Jaguar',
    'Land Rover',
    'Volvo',
  ]);

  const COGoldDealers = new Set([
    'Audi',
    'Nissan',
    'Ford',
    'Volkswagen',
    'Hyundai',
    'Kia',
    'Tesla',
    'Jaguar',
  ]);

  const stateGoldDealers = {
    Minnesota: MNGoldDealers,
    Colorado: COGoldDealers,
  };

  if (stateGoldDealers[userLocation.region]) {
    electricVehicles = electricVehicles.filter((ev) => {
      return stateGoldDealers[userLocation.region].has(ev.make);
    });
  }

  const maxIndex = vehicleCount;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount,
    firstEv + 4 <= maxIndex ? firstEv + 4 : firstEv + 4 - vehicleCount,
  ];


  const filteredEVs = [
    electricVehicles[evIndices[0]],
  ];

  const mobile = windowWidth <= 980;
  const tablet = windowWidth > 980  && windowWidth < 1150;

  if(!mobile && !tablet) {
       filteredEVs.push(electricVehicles[evIndices[1]], electricVehicles[evIndices[2]], electricVehicles[evIndices[3]]);
  } else if(!mobile && tablet) {
       filteredEVs.push(electricVehicles[evIndices[1]], electricVehicles[evIndices[2]]);
   }

  return (
    <section className="discover-section ">
       <div className="container">
      <div className="discover-container">
        <h2 className="title-head">
          <FormattedMessage
            id="homepage.vehicleCarousel.title"
            defaultMessage="There are more electric vehicles than ever."
            description="Vehicle Carousel Title"
          />
          <span className="title-head-discover">
            {' '}
            <FormattedMessage
              id="homepage.vehicleCarousel.title.discover"
              defaultMessage="Discover yours."
              description="Vehicle Carousel Title Discover"
            />
          </span>
        </h2>
        <h3 className="title-sub">
          <FormattedMessage
            id="homepage.vehicleCarousel.subTitle"
            defaultMessage="Compare the cost of your selection to a similar gas vehicle. An electric car can save you money {lineBreak}because it can be less expensive to own and maintain than a 100% gasoline-powered car."
            description="Vehicle Carousel Title"
            values={{
              lineBreak: <br />,
            }}
          />
        </h3>
        <Link to="/vehicles" className="btn btn-const" role="button">
          <FormattedMessage
            id="homepage.vehicleCarousel.compareVehiclesButton"
            defaultMessage="FIND YOUR ELECTRIC VEHICLE"
            description="FIND YOUR ELECTRIC VEHICLE"
          />
        </Link>
      </div>
       
       <div className="car-list-container">
      <div className="car-list row">
        <div className="col-sm-1 text-right">
          <button
            className="btn"
            style={{ marginTop: '205px', fontSize: '21px' }}
            disabled={firstEv === 0}
            onClick={() =>
              firstEv === 0 ? setFirstEv(maxIndex) : setFirstEv(firstEv - 1)
            }
          >
            {firstEv === 0 ? (
              <img src={greyArrow} alt="Carousel Left Arrow" />
            ) : (
              <img
                src={blackArrow}
                className="flip"
                alt="Carousel Left Arrow"
              />
            )}
          </button>
        </div>
        <div className='inner-car-slider'>
              {filteredEVs.map((ev, i) => (
                     <EVCard
                     ev={ev}
                     hasEvDetails={true}
                     hasIncentivesAndMatchScore={true}
                     />
              ))}
        </div>
        <div className="col-sm-1">
          <button
            className="btn"
            style={{ marginTop: '205px', fontSize: '21px' }}
            disabled={firstEv + filteredEVs.length === maxIndex}
            onClick={() =>
              firstEv === maxIndex ? setFirstEv(0) : setFirstEv(firstEv + 1)
            }
          >
            {firstEv + filteredEVs.length === maxIndex ? (
              <img
                src={greyArrow}
                className="flip"
                alt="Carousel Right Arrow"
              />
            ) : (
              <img src={blackArrow} alt="Carousel Right Arrow" />
            )}
          </button>
        </div>
      </div>
      </div>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
};
