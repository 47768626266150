import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserPrefsContext from '../../context/UserPrefs/UserPrefsContext';
import EVIncentives from './../../components/EVIncentives/EVIncentives';
import EVJumbotron from '../../client_customizations/components/EVJumbotron/EVJumbotron';
import CostOfOwnership from '../../client_customizations/components/CostOfOwnership/CostOfOwnership';
import getPaymentDetails from './../../functions/vehicle/getPaymentDetails';

import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner';
import { FormatCarName } from '../../utils/Helpers/Format';

const EV = ({
  match,
  electricVehicles,
  dealerLocations,
  userLocation,
  tabId,
  ip,
  uuid,
  zipcode,
}) => {
  const userPrefs = useContext(UserPrefsContext);

  let car = null;
  let paymentDetails = {};

  if (electricVehicles) {
    const evId = match ? match.params['evId'] : 0;
    car = electricVehicles.find((ev) => ev.handle === evId);
    paymentDetails = getPaymentDetails(
      car,
      userPrefs.get('monthsOfOwnership'),
      userPrefs.get('interestRateAsBasisPoints'),
      userPrefs.get('milesDrivenAnnually'),
      userPrefs.get('salesTax'),
      userPrefs.get('downPayment')
    );
  }

  useEffect(() => {
    document.title = car ? `${FormatCarName(car)}` : '';
  });

  const renderEV = electricVehicles ? (
    <>
      <EVJumbotron
        car={car}
        incentives={car.incentives}
        paymentDetails={paymentDetails}
      />
      <div className="container container-cost">
        <CostOfOwnership
          cars={[car, car.equivalent_gas_vehicle]}
          zipcode={zipcode}
        />
      </div>

      <EVIncentives incentives={car.incentives} car={car} zipcode={zipcode} />
    </>
  ) : (
    <div className="container text-center">
      <LoadingSpinner />
    </div>
  );

  return <>{renderEV}</>;
};

export default EV;

EV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
