import React, {useContext} from 'react';

import './EVAgeFilter.scss';
import { FormattedMessage } from 'react-intl';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

const EVAgeFilter = ({ id = 'ev-age', ...rest }) => {
    const userPrefs = useContext(UserPrefsContext);
    
     const handleUpdateEVAge = (e) => {
        userPrefs.set({ vehicleCondition: e.target.value });
     };

  return (
    <div id="EVAge">
      <button
        className={
          'ev-filter-btn ' +
          (userPrefs.get('vehicleCondition') === 'new'
            ? 'buttonActiveStyle'
            : 'buttonInactiveStyle')
        }
        onClick={(e) => handleUpdateEVAge(e)}
        value="new"
      >
        <FormattedMessage
          id="vehicles.new"
          defaultMessage="New"
          description="New Option"
        />
      </button>
      <button
        className={
          'ev-filter-btn ' +
          (userPrefs.get('vehicleCondition') === 'used'
            ? 'buttonActiveStyle'
            : 'buttonInactiveStyle')
        }
        value="used"
        onClick={(e) => handleUpdateEVAge(e)}
      >
        <FormattedMessage
          id="vehicles.used"
          defaultMessage="Used"
          description="Used Option"
        />
      </button>
    </div>
  );
};
export default EVAgeFilter;
