import cloneDeep from "lodash/cloneDeep";

// Primary sort on grantor, secondary sort on grant name
const Private = {
  alphabeticalSort: function (incentives, direction) {
    return incentives.sort((a, b) => {
      let name = (incentive) => `${incentive.grantor}`;
      let sub_name = (incentive) => `${incentive.name}`;
      switch (direction) {
        case "asc":
          return name(a).localeCompare(name(b)) === 0
            ? sub_name(a).localeCompare(sub_name(b))
            : name(a).localeCompare(name(b));
        case "desc":
          return name(b).localeCompare(name(a)) === 0
            ? sub_name(b).localeCompare(sub_name(a))
            : name(b).localeCompare(name(a));
        default:
          return incentives;
      }
    });
  },
  priceSort: function (incentives, direction) {
    return incentives.sort((a, b) => {
      let price = (incentive) =>
        incentive?.evaluation?.amount_in_purchase
      switch (direction) {
        case "asc":
          return price(a) - price(b);
        case "desc":
          return price(b) - price(a);
        default:
          return incentives;
      }
    });
  },
};
export const providerFilter = (incentives, provider) => {
  return incentives?.filter((incentive) => {
    if (incentive.name === "ClearCharge 12EV plan") {
      return false
    }
    if (!provider) {
      return true;
    }

    return (
      incentive.grantor === provider ||
      [
        'Country',
        'Section',
        'State',
        'District',
        'Municipality',
        'Miscellaneous Organization',
      ].includes(incentive.grantor_type)
    );
  });
};


export const sortIncentivesCatalog = (incentives, sortType, sortDirection) => {
  const clonedIncentives = cloneDeep(incentives);
  switch (sortType) {
    case "alphabetical":
      return Private.alphabeticalSort(clonedIncentives, sortDirection);
    default:
      return Private.priceSort(clonedIncentives, sortDirection);
  }
};

