import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';

import logo from '../../../assets/images/const_logo.svg';
import './Header.scss';
import { FormattedMessage } from 'react-intl';

const Header = ({
  page,
  zipcode,
  activePage,
  userLocation,
}) => {
  const [collapse, setCollapse] = useState(false);

  const isHomepage =
    window.location.pathname === '/' || window.location.pathname === null;


  return (
    <div className="Header">
      <div className="container-fluid" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div
            style={{
              paddingTop: '0.75rem',
              paddingBottom: '0.75rem',
            }}
          >
            <Link to="/" className={isHomepage ? 'active' : ''}>
              <img
                src={logo}
                className="homepagelogo"
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
              />
            </Link>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} />
          <Collapse isOpen={collapse} navbar>
            <Nav navbar>
              <NavItem>
                <Link
                  to="/vehicles"
                  className={activePage === 'vehicles' ? 'active' : ''}
                >
                  <span>
                    <FormattedMessage
                      id="electricVehiclesHeader"
                      defaultMessage="BROWSE EVS"
                      description="Shop Electric Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/compare-vehicles"
                  className={activePage === 'compare-vehicles' ? 'active' : ''}
                >
                  <span>
                    <FormattedMessage
                      id="header.compareVehicle"
                      defaultMessage="EXPLORE EV OWNERSHIP"
                      description="Compare Vehicles Header"
                    />
                  </span>
                </Link>
              </NavItem>

              <NavItem>
                <NavLink
                  title={page === 'incentives' ? 'Active Page' : null}
                  to="/incentives"
                >
                  <span>
                    <FormattedMessage
                      id="incentives"
                      defaultMessage="FIND INCENTIVES"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
