import React from 'react';
import './Footer.scss';
import FacebookIcon from '../../../assets/images/icons/Facebook.svg';
import InstagramIcon from '../../../assets/images/icons/Instagram.svg';
import YoutubeIcon from '../../../assets/images/icons/Youtube.svg';
import LinkedInIcon from '../../../assets/images/icons/Linkedin.svg';
import XIcon from '../../../assets/images/icons/X.svg';
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
import { FormattedMessage } from 'react-intl';


const Footer = () => {

  return (
    <div className="Footer">
      <div className="container">
        <div className="social-media-row">
          <div className="privacy-links">
            <a
              href="https://www.constellationenergy.com/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="footer.your"
                defaultMessage="Your Privacy Choices"
                description="Footer link"
              />
            </a>
            <a
              href="https://www.constellationenergy.com/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="footer.privacy"
                defaultMessage="Privacy Policy"
                description="Footer link"
              />
            </a>
            <a
              href="https://www.constellationenergy.com/terms-of-use.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="footer.terms"
                defaultMessage="Terms and Conditions"
                description="Footer link"
              />
            </a>
            <a
              href="https://www.constellation.com/energy-101/blog/blog-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="footer.blog"
                defaultMessage="Blog Policy"
                description="Footer link"
              />
            </a>
          </div>

          <div className="social-section">
            <p>
              <FormattedMessage
                id="footer.join"
                defaultMessage="Join the conversation:"
                description="Footer social media title"
              />
            </p>
            <div className="social-media-icons-container">
              <a
                href="http://www.facebook.com/ConstellationEnergy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="fa fa-facebook"
                  alt="Facebook Icon"
                  src={FacebookIcon}
                />
              </a>
              <a
                href="https://twitter.com/ConstellationEG"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="social-media-icon"
                  alt="Twitter Icon"
                  src={XIcon}
                />
              </a>
              <a
                href="https://www.youtube.com/ConstellationEnergy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="social-media-icon"
                  alt="Twitter Icon"
                  src={YoutubeIcon}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/constellation-energy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="social-media-icon"
                  alt="Linkedin Icon"
                  src={LinkedInIcon}
                />
              </a>
              <a
                href="https://www.instagram.com/constellationenergy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="social-media-icon"
                  alt="Linkedin Icon"
                  src={InstagramIcon}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="horizontal-line"></div>
        <div>
          <span className="disclaimer_container">
            <DisclaimerComponent
              clientName="zappynobackground"
              textStyle={{
                fontFamily: 'BentonSans',
                fontSize: '14px',
              }}
              imageStyle={{
                marginTop: '30px',
                maxWidth: '100%',
                marginBottom: '30px',
              }}
              wrapperStyle={{
                padding: '0px',
              }}
            />
          </span>
          
        </div>
        <div className="horizontal-line"></div>
        <div className="legal-info">
          <p>
            <FormattedMessage
              id="const.footerDisclaimerOne"
              defaultMessage="© 2024 Constellation. The offerings described herein are those of either Constellation NewEnergy, Inc., Constellation NewEnergy-Gas Division, LLC, Constellation Connect, LLC or Constellation Home Products & Services, LLC (d/b/a Constellation Home in Maryland and d/b/a Constellation in Georgia, Pennsylvania and Texas), each affiliates of each other. The prices of Constellation are not regulated by any state Public Utility Commission. You do not have to buy Constellation electricity, natural gas or any other products to receive the same quality regulated service from your local utility. Brand names and product names are trademarks or service marks of their respective holders. All rights reserved."
              description="const dislcaimer part one"
            />
          </p>
          <p>
            <FormattedMessage
              id="const.footerDisclaimerTwo"
              defaultMessage="Electricity or Gas Supplier License/Order #s: CA 1359, CTA0032; CT 06-07-11, 01-06; DE 00-162; DC GA06-2, EA01-5; GA GM-46; IL 16-0205, 17-0330; IA G-0010; ME 2000-989; MD IR-655, IR-311, IR-500, IR-228, 107-2276; MA GS-030, CS-015; MI U-14867, U-13660; NE NG-0043; NH DM 17-024; NJ GSL-0101, ESL-0016; OH 09-153G, 00-003E; PA A-125095, A-110036; OR ES4, RI 2379(Z1), D-96-6(E); TX 10014, B07305101; VA G-26, G-51, E-11A; TX Licenses HVAC TACLA 00107498R, Electrical TECL 343159, Service Plan TSCP #684, Plumbing #43217"
              description="const dislcaimer part two"
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
