import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleSection from '../../client_customizations/components/CollapsibleSection/CollapsibleSection';

import './FaqComponent.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { FormattedMessage } from 'react-intl';

const FaqComponent = ({ faqs }) => {
  if (!faqs)
    return (
      <div className="spinner-container">
        <LoadingSpinner />
      </div>
    );
    
    const style = {
      fontFamily: 'Benton Sans',
      color: '#212529 !important',
      fontWeight: 'bold',
    };

    return (
      <div className="CollapsibleSectionContainer">
        {faqs.map((content, index) => <CollapsibleSection
          key={`article-${content.section}-${index}`}
          headLine={content.articleTitle}
        >
          <FormattedMessage
            id={`article-${index}`}
            defaultMessage={content.articleBody}
            values={{
              b: (...msg) => <b style={style}>{msg}</b>,
              u: (...msg) => <u>{msg}</u>,
              i: (...msg) => <i>{msg}</i>,
              br: () => <br />,
              h1: (...msg) => <p style={{ ...style, fontSize: 20 }}>{msg}</p>,
              h2: (...msg) => <p style={{ ...style, fontSize: 18 }}>{msg}</p>,
              h3: (...msg) => <p style={{ ...style, fontSize: 16 }}>{msg}</p>,
            }}
          />
        </CollapsibleSection>)}
      </div>
    );
};

export default FaqComponent;
FaqComponent.propTypes = {
  faqs: PropTypes.array,
};
