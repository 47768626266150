import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Select from "../../shared/InputElements/Select";
import { useIntl } from 'react-intl';

const SelectPurchaseMethod = ({
  id = "select-purchase-method",
  incentives,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "ev.purchaseMethod", defaultMessage: "Purchase Method"})
  const optionNames = [
    intl.formatMessage({ id: 'ev.cash', defaultMessage: 'Cash' }),
    intl.formatMessage({ id: 'ev.lease', defaultMessage: 'Lease' }),
  ];
  const options = ["cash", "lease"]
  if (!incentives) {
    options.push('loan')
    optionNames.push(
      intl.formatMessage({ id: 'ev.loan', defaultMessage: 'Loan' })
    );
  }
  

  return (
    <Select
      id={id}
      value={userPrefs.get("purchaseMethod")}
      label={label}
      optionNames={optionNames}
      optionValues={options}
      handler={e => userPrefs.set({ purchaseMethod: e.target.value })}
    />
  );
};

export default SelectPurchaseMethod;

SelectPurchaseMethod.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string
};
