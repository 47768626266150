import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsThousands } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';

const SlideMilesDrivenAnnually = ({
  id = "miles-driven-annually-range",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "ev.milesDrivenAnnually", defaultMessage: "Miles Driven Per Year"})
  const description = val => {
    return `${FormatAsThousands(val)} ${intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"})}`;
  };
 
  

 const tooltip = intl.formatMessage(
        {
          id: 'minimumRangeTooltip',
          defaultMessage:
            'Estimate what percentage of your driving miles will be completely electric. {br} If you recharge daily and drive less per day than the all-electric range, your percentage will be near 100%. {br} For most typical driving beyond all-electric range. 50% is a safe assumption.',
        },
        {
          br: (
            <br />
          ),
        }
      )
  return (
    <Range
      id={id}
      value={userPrefs.get('milesDrivenAnnually')}
      label={label}
      rangeMin={2000}
      rangeMax={50000}
      rangeStep={2000}
      description={description}
      tooltip={tooltip}
      handler={(e) => userPrefs.set({ milesDrivenAnnually: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMilesDrivenAnnually;

SlideMilesDrivenAnnually.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
