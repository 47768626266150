import React from 'react';
import PropTypes from 'prop-types';
import { Link} from 'react-router-dom';
import getFilteredData from '../../../../utils/Helpers/getFilteredData';
import IncentiveCatalog from '../../../../components/IncentiveCatalog/IncentiveCatalog';
import { FormattedMessage } from 'react-intl';

const HomepageIncentives = ({ incentives }) => {

  incentives = getFilteredData(
    [
      { field: 'grantor', value: 'Federal', count: 1 },
      { field: 'grantor_type', value: 'State', count: 2 },
      { field: 'grantor_type', value: 'Power Supplier', count: 3 },
      { field: 'grantor_type', value: 'Power Supplier', count: 4 },
    ],
    incentives
  );

  return (
    <section className="homepage-incentives">
      <div className="homepage-incentives-title-container">
        <h2 className="homepage-incentives-title">
          <FormattedMessage
            id="homepage.incentives.title"
            defaultMessage="Maximize potential EV incentives and tax credits."
            description="Homepage Incentives Title"
          />
        </h2>
        <p className="homepage-incentives-title-sub">
          <FormattedMessage
            id="homepage.incentives.subTitle"
            defaultMessage="See how much you could save getting behind the wheel of an EV,
                whether you are buying or leasing. {lineBreak} Incentives are personalized for
                where you live."
            description="Homepage Incentives Sub Title"
            values={{
              lineBreak: <br />,
            }}
          />
        </p>
      </div>
      <div className="homepage-home-charger-get" style={{ width: '100%' }}>
        <Link to="/incentives">
          <button className="btn btn-const">
            <FormattedMessage
              id="personalizeIncentives"
              defaultMessage="Personalize Incentives"
              description="Personalize Incentives"
            />
          </button>
        </Link>
      </div>
      <IncentiveCatalog incentives={incentives} carousel={true} category="all" />
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array,
};
