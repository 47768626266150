 import React, { useContext } from "react";
import Input from "../../../components/shared/InputElements/Checkbox";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from "react-intl";
import ToolTip from "../../../components/shared/ToolTip/ToolTip";

  
const EVFilterAvailableNow = () => {
  const userPrefs = useContext(UserPrefsContext);
  const availableNow = userPrefs.get("vehicleAvailableNowFilter");
  const intl = useIntl();


    return (
        <div className="availableNowContainer">
            <Input
                id="available-now-filter"
                value={availableNow}
                label={ intl.formatMessage
                  ? intl.formatMessage({
                    id: "evs.availableNow",
                    defaultMessage: "Available Now",
                }): "Available Now"}
                handler={(e) => {
                    userPrefs.set({ vehicleAvailableNowFilter: e.target.checked});
                }}
          />
          <ToolTip message="Available Now" id="available-now-tooltip"/>
        </div>
          
    )
}

export default EVFilterAvailableNow;