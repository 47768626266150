import React, { useState, useContext } from 'react';
import PropTypes from "prop-types";

import IncentivePreferences from "../IncentivePreferences/IncentivePreferences"
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { ValidateFiveDigitCode } from '../../../utils/Helpers/USPostalCodeValidator';

import SlideMilesDrivenDaily from "../../../components/InputComponents/SlideMilesDrivenDaily/SlideMilesDrivenDaily";
import SlideMaxBudget from "../../../components/InputComponents/SlideMaxBudget/SlideMaxBudget";
import SlideMinSeats from "../../../components/InputComponents/SlideMinSeats/SlideMinSeats";
import ControlledInputZipcode from "../../../components/InputComponents/InputZipcode/ControlledInputZipcode";

import { FormattedMessage, useIntl } from "react-intl";
import EVFilterControls from "../EVFilterControls/EVFilterControls";
import EVFilterType from "./EVFilterType";
import EVFilterAvailableNow from "./EVFilterAvailableNow";

const MatchScoreOptions = ({ electricVehicles, location, mobile }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const [workingZipcode, setWorkingZipcode] = useState(
    userPrefs.get('zipcode')
  );

   const handleFormSubmit = () => {
     if (ValidateFiveDigitCode(workingZipcode)) {
       userPrefs.syncWorkingZipcode();
       userPrefs.toggleIncentivePrefsModal();
     }
   };

  return (
    <>
      <div className="input-well MatchScoreOptions margin-top">
        {!mobile ? <p className="h3">
          <FormattedMessage
            id="evs.matchScoreOptions"
            defaultMessage="Refine Match Score"
            description="Refine Match Score"
          />
        </p> : null}
        <ControlledInputZipcode
          zipcode={userPrefs.get('zipcode')}
          handleSubmit={(e) => {
            handleFormSubmit();
          }}
          workingZipcode={workingZipcode}
          setZipcode={(e) => {
            setWorkingZipcode(e.target.value);
            if (ValidateFiveDigitCode(e.target.value)) {
              userPrefs.set({ workingZipcode: e.target.value });
            }
          }}
          id="input-zipcode-for-rate-comp-launch"
          isUpdating={userPrefs.zipcodeIsUpdating}
          isNotFound={userPrefs.zipcodeIsNotFound}
          hideValidateRequest
        />

        <EVFilterControls vehicles={electricVehicles} />

        <SlideMaxBudget />
        <IncentivePreferences
          electricVehicles={electricVehicles}
          location={location}
          titleText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: 'personalizeIncentives',
                  defaultMessage: 'Personalize Incentives',
                })
              : 'Personalize Incentives'
          }
          btnText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: 'personalizeIncentives',
                  defaultMessage: 'Personalize Incentives',
                })
              : 'Personalize Incentives'
          }
          btnSubtle
        />

        <SlideMilesDrivenDaily />

        <SlideMinSeats />

        <EVFilterType />

        <EVFilterAvailableNow />
      </div>

      {!mobile ? <p className="selected-subText">
        <FormattedMessage
          id="evs.thirdParty"
          defaultMessage="We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability."
          description="Vehicles Disclaimer"
          values={{
            company: process.env.REACT_APP_FULL_COMPANY_NAME,
          }}
        />
      </p> : null}
    </>
  );
};

export default MatchScoreOptions;

MatchScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
