import React from 'react';
import PropTypes from 'prop-types';
import LinkCard from '../../LinkCard/LinkCard';
import {FormattedMessage } from 'react-intl';

const HomepageBigPromise = () => {
  return (
    <section className="white">
      <div className="homepage-jumbotron">
        <div className='title-banner-box'>
          <h1 className="title-banner">
            <FormattedMessage
              id="homepage.banner.title"
              defaultMessage="{EVe}erything EV."
              description="{EVe}erything EV."
              values={{
                EVe: (
                  <p style={{ color: '#CADB2E' }}>
                    <FormattedMessage
                      id="EVe"
                      defaultMessage="EV"
                      description="EV"
                    />
                  </p>
                ),
              }}
            />
          </h1>
          <p className="subtitle-banner">
            <FormattedMessage
              id="homepage.banner.subtitle"
              defaultMessage="Estimate and compare costs, savings, incentives & more."
              description="Estimate and compare costs, savings, incentives & more."
            />
          </p>
        </div>
      </div>

      <div className="homepage-banner"></div>
      <div className="link-card-container">
        <LinkCard type="BROWSE_VEHICLES" />
        <LinkCard type="COMPARE_VEHICLES" />
        <LinkCard type="DISCOVER_INCENTIVES" />
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
  userLocation: PropTypes.string,
};
