import React from 'react';
import PropTypes from 'prop-types';

import './LinkCard.scss';

import BROWSE_VEHICLES from '../../assets/images/icons/linkCard_evs.svg';
import COMPARE_VEHICLES from '../../assets/images/icons/linkCard_explore.svg';
import DISCOVER_INCENTIVES from '../../assets/images/icons/linkCard_incentives.svg';
import BROWSE_VEHICLES_HOVER from '../../assets/images/icons/linkCard_evs_hover.svg';
import COMPARE_VEHICLES_HOVER from '../../assets/images/icons/linkCard_explore_hover.svg';
import DISCOVER_INCENTIVES_HOVER from '../../assets/images/icons/linkCard_incentives_hover.svg';
import { Link } from 'react-router-dom';
import { useIntl , FormattedMessage } from 'react-intl';

const LinkCard = ({ type }) => {
  const intl = useIntl();
  let src, link, button_title, src_hover;
  const mobile = window.innerWidth < 720;

  switch (type) {
    case 'BROWSE_VEHICLES':
      src = BROWSE_VEHICLES;
      src_hover = BROWSE_VEHICLES_HOVER;
      link = '/vehicles';
      button_title = intl.formatMessage ? (
        !mobile ? (
          intl.formatMessage({
            id: 'homepage.browse',
            defaultMessage: 'Discover Electric Vehicles',
          })
        ) : (
          <FormattedMessage
            id="homepage.browse.mobile"
            defaultMessage="Discover {linebreak} EVs"
            values={{ lineBreak: <br /> }}
          />
        )
      ) : (
        'Discover Electric Vehicles'
      );
      break;
    case 'COMPARE_VEHICLES':
      src = COMPARE_VEHICLES;
      src_hover = COMPARE_VEHICLES_HOVER;
      link = '/compare-vehicles';

      button_title = intl.formatMessage
        ? intl.formatMessage({
            id: 'homepage.compare',
            defaultMessage: 'Explore EV Ownership',
          })
        : 'Explore EV Ownership';
      break;
    case 'DISCOVER_INCENTIVES':
      src = DISCOVER_INCENTIVES;
      src_hover = DISCOVER_INCENTIVES_HOVER;
      link = '/incentives';
      button_title = intl.formatMessage
        ? intl.formatMessage({
            id: 'homepage.incentives',
            defaultMessage: 'Maximize Incentives',
          })
        : 'Maximize Incentives';
      break;

    default:
      return null;
  }

  return (
    <div className="link-card">
      <Link to={link} className="evc-card">
        <img src={src} alt="" className='evc-card-image'/>
        <img src={src_hover} alt="" className='evc-card-image-hover'/>
        <button className="btn btn-homepage">{button_title}</button>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string,
};
